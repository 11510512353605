import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "it-is-impossible-to-login"
    }}>{`It is impossible to login`}</h1>
    <p>{`In case you cannot login you are presented with an error message like this:`}</p>
    <img src="/public/03-img02.png" style={{
      "maxWidth": "500px",
      "width": "90%",
      "marginLeft": "auto",
      "marginRight": "auto",
      "display": "block"
    }} />
    <h2 {...{
      "id": "problems-running-vpm-on-ios-mobile-safari-in-ios-13"
    }}>{`Problems running VPM on iOS Mobile Safari in iOS 13`}</h2>
    <p>{`In iOS 13 Mobile Safari has security settings that prevent the VPM application from working in some cases.`}</p>
    <p>{`To re-enable using the application please go to `}<em parentName="p">{`Settings -> Safari`}</em>{` and `}<strong parentName="p">{`disable the option "Prevent Cross-Site Tracking"`}</strong>{`.`}</p>
    <img src="/public/03-img01.jpg" style={{
      "maxWidth": "500px",
      "width": "70%",
      "marginLeft": "auto",
      "marginRight": "auto",
      "display": "block"
    }} />
    <p><strong parentName="p">{`Please note`}</strong>{` that disabling Cross-Site Tracking Prevention disables a default security mechanism that prevent adverts tracking which rely on third-party site requests which VPM also uses for the login system. You can check the VPM privacy policy to find more about our reliance on third-party vendors and data privacy concerns.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      